.mine-work .mans {
  background: white;
  flex-direction: column !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 15px 17px;
  border-radius: 10px;
}
.mine-work .ml-4 {
  margin-left: 0 !important;
  margin-top: 10px;
}
.md\:gap-x-0 {
  column-gap: 1.3rem;
}

@media (min-width: 768px) {
  .md\:grid-cols-0 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
